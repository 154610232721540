import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import App from './App_feedback';
// import reportWebVitals from './reportWebVitals';


// createGlobalStyle нужен для создания глобальных стилей
import styled, { createGlobalStyle } from 'styled-components';

// получаем значение для целевой платформы
// import { sberBox } from '@sberdevices/plasma-tokens/typo';
// import { sberPortal } from '@sberdevices/plasma-tokens/typo';
// import { touch } from '@sberdevices/plasma-tokens/typo';
import { mobile } from '@sberdevices/plasma-tokens/typo';
// получаем стилевые объекты для нашего интерфейса
import { body1, headline2 } from '@sberdevices/plasma-tokens';

// получаем тему персонажа
// import { darkJoy, lightSber } from '@sberdevices/plasma-tokens/themes';
import { lightSber, darkSber } from '@sberdevices/plasma-tokens/themes';
// import { darkSber } from '@sberdevices/plasma-tokens/themes';
// получаем цвета для нашего интерфейса
import { text, background, gradient } from '@sberdevices/plasma-tokens';

// padding: 30px 20px 30px 60px; 
// padding: 30px 20px 30px 20px; 
const AppStyled = styled.div`
    ${body1}
    
    `;
    // background: url(/valli_main_bg_sketch.jpg);
    // min-height: 100%;
    // background-position: cover;

// создаем react-компонент c глобальными стилями типографики
const TypoScale = createGlobalStyle(mobile);
// const TypoScale = createGlobalStyle(sberPortal);

// создаем react-компонент для подложки
const DocStyles = createGlobalStyle`
    /* stylelint-disable-next-line selector-nested-pattern */
    html {
        color: ${text};
        background-color: ${background};
        background-image: ${gradient};
        /** необходимо залить градиентом всю подложку */
        min-height: 100vh;
    }

    html, body { min-height: 100vh; } 
    body { 
        background: #011a17 url('/sbernight_opt.jpg') center bottom no-repeat; 
        background-size: cover; 
        /* 
        background-size: contain; 
        background-position: center -130px; */
    }
`;
// создаем react-компонент для персонажа
// const Theme = createGlobalStyle(lightSber);
const Theme = createGlobalStyle(darkSber);

ReactDOM.render(
  <React.StrictMode>

    <AppStyled>
      {/* Используем глобальные react-компоненты один раз */}
      <TypoScale />
      <DocStyles />
      <Theme />

      <App />

    </AppStyled>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
