// import logo from './logo.svg';
// import './App.css';

import React from 'react';

import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  // useHistory,
  // Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
import MainPage from './feedback/MainPage'; 
import TopPage from './feedback/TopPage'; 
import MudPage from './feedback/MudPage'; 
// import PeterFacePage from './st/PeterFacePage'; 
// import EpochPage from './st/EpochPage'; 
// import TestPage from './st/StyleTrasferPage'; 
// import PeterFaceLightPage from './st/PeterFacePage'; 
// import StartPage from './st/StartNightPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './antd.dark.css'

// import { notification, Button } from 'antd';
// import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
// import  {
//       Button, Container,

// } from '@sberdevices/ui';

import axios from 'axios';





class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        list: [],
    }
    
  }

  render() {
    return (
      <Router>



          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
          </nav> */}

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>

            {/* <Route path="/test">
                <TestPage />
            </Route>
            <Route path="/test2">
                <PeterFaceLightPage />
            </Route> */}

            <Route path="/top">
                <TopPage />
            </Route>
            <Route path="/mud">
                <MudPage />
            </Route>

            
            <Route path="/en">
                <MudPage lang={'en'} />
            </Route>
            <Route path="/ru">
                <MudPage lang={'ru'} />
            </Route>
            <Route path="/">
                <MudPage lang={'ru'}  />
            </Route>

            {/* <Route path="/">
                <Redirect to="/meetings" />
            </Route> */}

          </Switch>



      </Router>
    )
  }
}

export default App;
