

// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
import  {
      Button, Container,
      TextField,
      ActionButton,
      Spinner,
    //   useToast,
    //   ToastProvider

} from '@sberdevices/plasma-ui';

import {
    IconEye,
    IconArrowRight
} from '@sberdevices/plasma-icons'

import axios from 'axios';

// import QRmodal from './qrmodal'


import { Row as Row2, Col as Col2 } from 'antd';

import { IRow, ICol } from '../st/irow'

import utils from '../st/utils'

import { message } from 'antd';

import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()



export default function MainPage({lang}) {

    let [text, setText] = useState('')
    let [name, setName] = useState('')
    let [lock, setLock] = useState(false)
    // let [user_id, setUserId] = useState('')
    // let [lang, setLang] = useState('ru')

    const history = useHistory()


    let [is_loading, setLoadading] = useState(false)

    const short_err = () => {
        message.error({
            content: lang == 'ru' ? 'Слишком короткое слово :-(' : 'Too short word :-(',
            className: 'custom-class',
            style: {
                marginTop: '30vh',
                borderRadius: '100px',
                padding: '40px'
            },
        });
    };

    const success = () => {
        message.success({
            content: lang == 'ru' ? 'Спасибо! Ваш комментарий отправлен' : 'Thank you! Your comment has been sent',
            className: 'custom-class',
            style: {
                marginTop: '40vh',
                borderRadius: '100px',
                padding: '40px'
            },
        });
    };

    const send = () => {
        if (text.length < 3) {
            short_err();
            return
        }
        setLoadading(true)
        setLock(true)


        ;(async () => {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise
            const result = await fp.get()
          
            // This is the visitor identifier:
            const visitorId = result.visitorId
            // setUserId(visitorId)
            console.log(visitorId)

            axios.post('http://45.89.225.221:5011/api/save_word', {
                "text": text,
                "lang": lang,
                "user_id": visitorId,
            }).then(res => {
                console.log('res', res)
                setLoadading(false)
                success()
            }, err => {
                console.log('err', err)
                setLoadading(false)
            });

        })()
        // console.log(visitorId)

    }

    return (
        <div>
            <Container style={{maxWidth:'500px', margin:'0 auto'}}>

                <div style={{textAlign:'center', marginTop:'2rem'}}>
                    <br />
                    <img className="sber_180" src={lang == 'ru' ? "/sber_180.png" : "/sber_180_en.png"} style={{ width:'200px'}} />

                    {/* <Button style={{position:'absolute', right:0}} onClick={() => {setLang(lang == 'ru' ? 'en' : 'ru')}}>{ lang == 'ru' ? 'EN' : 'RU' }</Button> */}
                    <Button view={'clear'} style={{position:'absolute', right:'30px'}} onClick={() => {history.push(lang == 'ru' ? '/en' : '/ru')}}>{ lang == 'ru' ? 'EN' : 'RU' }</Button>

                    <h1 style={{fontSize: '1.6rem', lineHeight:1.2, margin:'1rem 0 1rem'}}> 
                        {lang == 'ru' ? 
                            <>Поделитесь впечатлениями <br/>о SberNight 2021</> : 
                            <>Share your experience <br/>of SberNight 2021</>
                        }
                    </h1>
                    
                    {lang == 'ru' ? 
                            <p>Напишите одно слово, <br/>которое раскроет ваше настроение</p> :
                            <p>Write one word <br/>that will reveal your mood</p>
                        }
                    
                </div>




                <TextField 
                    // as="textarea"
                    className="TextField"
                    label={lang == 'ru' ? "Ваша главная эмоция (1 слово)" : "Your main emotion (1 word)"}
                    // rows={10}
                    value={text} 
                    style={{textAlign:'center' }}
                    // defaultValue={val} 
                    onChange={e => setText(e.target.value.replace(' ', ''))} 
                    // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
                    // style={{ margin: '10px auto', width:'100%'}}
                />

                <br />

                {/* <ActionButton view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}> */}
                        {/* <IconArrowRight color="inherit" size="s" /> */}
                        {/* <IconEye color="inherit" size="s" /> */}
                        {/* <Spinner color="white" size={20}/> */}
                {/* </ActionButton> */}

                {/* <Button view="primary" onClick={() => { showToast(toastText, position, timeout, fade); }} >Отправить комментарий</Button> */}
                <Button view="primary" onClick={() => { send() }} disabled={lock} >
                {/* <Button view="primary" onClick={() => { send() }} > */}
                    { is_loading ? <Spinner color="white" size={20} /> : lang == 'ru' ? 'Отправить' : 'Send' }
                </Button>




                {/* <ToastProvider /> */}
                    {/* <div className="App">Hello, world!</div> */}
                {/* </ToastProvider> */}
                

                <div className="footer" style={{textAlign:'center', margin: '2rem 0'}}>
                    <small>powered by</small>
                    <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
                </div>

            </Container>

            <style>
              {`

                    body {
                        background-position-x: 70%;
                    }
                    .TextField input { text-align: center; }
                    
              `}
          </style>

        </div>
    )
}