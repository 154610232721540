

// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
import  {
      Button, Container,
      TextField,
      ActionButton,
      Spinner,
    //   useToast,
    //   ToastProvider

} from '@sberdevices/plasma-ui';

import {
    IconEye,
    IconArrowRight
} from '@sberdevices/plasma-icons'

import axios from 'axios';

// import QRmodal from './qrmodal'


import { Row as Row2, Col as Col2 } from 'antd';

import { IRow, ICol } from './../st/irow'

import utils from './../st/utils'

import { message } from 'antd';


import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()






export default function MainPage() {

    let [text, setText] = useState('')
    let [name, setName] = useState('')


    let [is_loading, setLoadading] = useState(false)


    const success = () => {
        message.success({
            content: 'Спасибо! Ваш комментарий отправлен',
            className: 'custom-class',
            style: {
                marginTop: '40vh',
                borderRadius: '100px',
                padding: '40px'
            },
        });
    };

    const send = () => {

        // axios.post('http://45.89.225.221:8101/api/question', {
        //         "question_text":"лол кек, но вот как-то так",
        //         "user_id":"1466",
        //         "lang":"ru",
        //         "name":"ht"
        // }).then(res => console.log('res', res), err => console.log('err', err));


        // axios.post('http://45.89.225.221:5011/api/save_word', {
        //         "text": text,
        //         "lang": "ru"
        // }).then(res => console.log('res', res), err => console.log('err', err));



        // ;(async () => {
        //     // Get the visitor identifier when you need it.
        //     const fp = await fpPromise
        //     const result = await fp.get()
          
        //     // This is the visitor identifier:
        //     const visitorId = result.visitorId
        //     console.log(visitorId)
        //   })()



        // axios.put('http://45.89.225.221:8101/api/question', {
        //     question_id: 1,
        //     // question_text: ,
        //     // user_id: ,
        //     // lang: ,
        //     // name: ,
        //     status: 100,
        //     // weight: ,
        // });

        axios.get('http://45.89.225.221:8101/api/get_q_list', {})
            .then(res => console.log('res', res), err => console.log('err', err));

        setLoadading(true)
        setTimeout(()=> {
            setLoadading(false);
            success()
            setText('')
            setName('')
        },300)
    }

    return (
        <div>
            <Container style={{maxWidth:'500px', margin:'0 auto', color:'#151a22'}}>

                <div style={{textAlign:'center', marginTop:'2rem'}}>
                    <br />
                    <img className="sber_180" src="/sber_180.png" style={{ width:'200px'}} />

                    <h1 style={{fontSize: '3rem', lineHeight:1, margin:'1rem 0 1rem'}}>Обратная связь</h1>
                    <p>Оставьте ваш комментарий <br/>или&nbsp;зайдайте&nbsp;вопрос</p>
                </div>



                <TextField 
                    as="textarea"
                    className="TextFieldArea"
                    label="Напишите слово или фразу" 
                    // rows={10}
                    value={text} 
                    // defaultValue={val} 
                    onChange={e => setText(e.target.value)} 
                    // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
                    style={{ margin: '10px auto', width:'100%'}}
                />

                <TextField 
                    // as="textarea"
                    className="TextField"
                    label="Представьтесь (опционально)"
                    // rows={10}
                    value={name} 
                    // defaultValue={val} 
                    onChange={e => setName(e.target.value)} 
                    // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
                    // style={{ margin: '10px auto', width:'100%'}}
                />

                <br />

                {/* <ActionButton view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}> */}
                        {/* <IconArrowRight color="inherit" size="s" /> */}
                        {/* <IconEye color="inherit" size="s" /> */}
                        {/* <Spinner color="white" size={20}/> */}
                {/* </ActionButton> */}

                {/* <Button view="primary" onClick={() => { showToast(toastText, position, timeout, fade); }} >Отправить комментарий</Button> */}
                <Button view="primary" onClick={() => { send() }} >
                    { is_loading ? <Spinner color="white" size={20} /> : 'Отправить комментарий' }
                </Button>




                {/* <ToastProvider /> */}
                    {/* <div className="App">Hello, world!</div> */}
                {/* </ToastProvider> */}
                

                <div className="footer" style={{textAlign:'center', margin: '2rem 0'}}>
                    <small>powered by</small>
                    <img src="sberai_logo_black.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
                </div>

            </Container>

            <style>
              {`

                    html, body { min-height: 100vh; } 

                    body { 
                        background: #d5dae1 url('/sberlight_bg.jpg') center top no-repeat; 
                    }

                    .TextField input {
                        color:#2e3340;
                        background: #ffffff9b;
                    }
                    .TextFieldArea textarea { 
                      height: 30vh; 
                      resize: none;
                      white-space: normal;
                      background: #ffffff9b;
                      overflow: auto;
                      color:#2e3340;
                    }
                    .TextField input:focus,
                    .TextFieldArea textarea:focus { 
                      background: #fff;
                    }
                    .TextField span,
                    .TextFieldArea span {
                        color: #2e334077;
                    }
                    
              `}
          </style>

        </div>
    )
}